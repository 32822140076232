import React from "react"
// import { Link } from "gatsby"
import "./button.scss"


const Button = ({url, text, title, className, iconClass, linkId}) => (
	<a id={linkId} href={url} className={"btn btn-primary " + className} title={title} target="_blank" rel="noreferrer"><i className={iconClass}></i>
		<span>{text}</span>
	</a>
)

export default Button