import React from "react"
import { useState } from "react"
import "./iconCard.scss"
import InternalButton from "../internalButton/internalButton"
import ModalOverlay from "../modal/modal"
import DownloadIcon from "../../images/icons/download-icon.svg"
import RightArrow from "../../images/icons/right-arrow-icon.svg"

import { Link } from "gatsby"

function IconCard({icon, alt, cardClass, cardTitle, cardText, cardList, hasButton, btnLink, btnTitle, btnText, btnClass, hasLink, link, linkTitle, linkText, linkClass, isExternalLink, isModalLink, modalLink, modalLinkId, trackingID, isBlur, buttonIcon, isClickableTitle, linkId}) {
    let cList = '';
    if (cardList !== undefined && cardList !== '') {
        cardList = cardList.split('|');
        // console.log(cardList);
        cList =
            <ul>
                {cardList.map(item => (<li>{item}</li>))}
            </ul>
    }
    // const [isModalOn, setIsModalOn] = false;
    
    // const goToLink = () => {
      
    // }

    return (
      <figure className={`icon-card  ${cardClass}`}>
        {icon ? <img src={icon} alt={alt} className={`${isBlur ? 'blur' : ''} form-img`} /> : <span style={{marginLeft: ".9rem"}}></span>}
        <figcaption>
          {!isClickableTitle && <h4 dangerouslySetInnerHTML={{ __html: cardTitle }} />}
          {cardText && <p>{cardText}</p>}
          {cList}

          {hasButton && (
            <InternalButton
              url={btnLink}
              title={btnTitle}
              text={btnText}
              className={btnClass}
              buttonIcon={buttonIcon}
              linkId={linkId}
            />
          )}
          {hasLink && (
            <Link id={linkId} to={link} title={linkTitle} className={linkClass}>
              {linkText}
            </Link>
          )}
          {isExternalLink && (
            <a
              href={link}
              title={linkTitle}
              className={linkClass}
              target="_blank"
              rel="noreferrer"
              id={linkId}
            >
              {linkText}
              {linkClass == "download-icon" && <img src={DownloadIcon} />}
            </a>
          )}
          
          {isModalLink && (
            <ModalOverlay 
              message={[
                <strong>YOU ARE NOW LEAVING</strong>,
                <br />,
                "SanofiCareASSIST.com",
                <br />,
                <p>
                  Sanofi does not review the information contained on
                  this website and/or database for content, accuracy, or
                  completeness. Use of and access to this information is subject
                  to the terms, limitations, and conditions set by the website
                  and/or database producer. Sanofi makes no
                  representation as to the accuracy or any other aspect of the
                  information contained on such website and/or database, nor
                  does Sanofi necessarily endorse such website and/or
                  database.
                </p>,
              ]}
              buttontxt="Continue"
              link={modalLink}
              trackingID={modalLinkId}
            >
              {isClickableTitle && <h4 dangerouslySetInnerHTML={{ __html: cardTitle }} />}
              <button id={modalLinkId} class="view-link modal-trigger">
                <img src={RightArrow} />
              </button>
            </ModalOverlay>
          )}
        </figcaption>
      </figure>
    );
}

export default IconCard