import React from "react";
import ExpandingCard from "../../../components/ExpandingCard/ExpandingCard";
import IconCard from "../../../components/iconCard/iconCard";

import enrollmentForm from "../../../images/icons/elitek/enrollment-form.png";
import spanishEnrollmentForm from "../../../images/icons/elitek/spanish-enrollment-form.png";
import patientResourceNavigationFlashcard from "../../../images/icons/elitek/patient-resource-navigation-flashcard.png";
import patientOverviewFlashcard from "../../../images/icons/elitek/patientOverviewFlashcard.jpg";
import spanishOverviewFlashcard from "../../../images/icons/elitek/spanishOverviewFlashcard.jpg";

const ResourceLinks = () => {
  return (
    <>
        <div className=" full p-b-0">
          <div className="mt-1 ">
            <p className="mb-2 fs-16">
            Our Care Managers can provide guidance around additional support resources.
            </p>
            <div className="grid-cols-sm-1 grid-cols-2 gap-20">
              <IconCard
                alt="HealthCare"
                cardClass="external-link-card clickable-title"
                cardTitle="HealthCare.gov"
                cardText=""
                hasButton={false}
                isModalLink={true}
                modalLink="https://healthcare.gov"
                modalmodalLinkId="healthcare-gov"
                isClickableTitle={true}
                modalLinkId="patient-elitek-healthcare-gov"
              />
              <IconCard
                alt="Medicaid.gov"
                cardClass="external-link-card clickable-title"
                cardTitle="Medicaid.gov"
                cardText=""
                hasButton={false}
                isModalLink={true}
                modalLink="https://medicaid.gov"
                modalmodalLinkId="medicaid-gov"
                isClickableTitle={true}
                modalLinkId="patient-elitek-medicaid-gov"
              />
              <IconCard
                alt="Medicare.gov"
                cardClass="external-link-card clickable-title"
                cardTitle="Medicare.gov"
                cardText=""
                hasButton={false}
                isModalLink={true}
                modalLink="https://medicare.gov"
                modalmodalLinkId="medicare-gov"
                isClickableTitle={true}
                modalLinkId="patient-elitek-medicare-gov"
              />
              <IconCard
                alt="Patient Advocate Foundation"
                cardClass="external-link-card clickable-title"
                cardTitle="Patient Advocate Foundation"
                cardText=""
                hasButton={false}
                isModalLink={true}
                modalLink="https://www.patientadvocate.org/"
                modalmodalLinkId="patientadvocate-org"
                isClickableTitle={true}
                modalLinkId="patient-elitek-patientadvocate-org"
              />
              <IconCard
                alt="Patient Advocate Network Foundation"
                cardClass="external-link-card clickable-title"
                cardTitle="Patient Advocate Network Foundation"
                cardText=""
                hasButton={false}
                isModalLink={true}
                modalLink="https://www.panfoundation.org/"
                modalmodalLinkId="panfoundation-org"
                isClickableTitle={true}
                modalLinkId="patient-elitek-panfoundation-org"
              />
              <IconCard
                alt="American Cancer Society"
                cardClass="external-link-card clickable-title"
                cardTitle="American Cancer Society"
                cardText=""
                hasButton={false}
                isModalLink={true}
                modalLink="https://cancer.org"
                modalmodalLinkId="cancer-org"
                isClickableTitle={true}
                modalLinkId="patient-elitek-cancer-org"
              />
              <IconCard
                alt="Cancer Support Community"
                cardClass="external-link-card clickable-title"
                cardTitle="Cancer Support Community"
                cardText=""
                hasButton={false}
                isModalLink={true}
                modalLink="https://www.cancersupportcommunity.org/"
                modalmodalLinkId="cancersupportcommunity-org"
                isClickableTitle={true}
                modalLinkId="patient-elitek-cancersupportcommunity-org"
              />
              <IconCard
                alt="National Comprehensive Cancer Network<sup>®</sup>"
                cardClass="external-link-card clickable-title"
                cardTitle="National Comprehensive Cancer Network<sup>®</sup>"
                cardText=""
                hasButton={false}
                isModalLink={true}
                modalLink="https://www.nccn.org/patientresources/patient-resources"
                modalmodalLinkId="nccn-org"
                isClickableTitle={true}
                modalLinkId="patient-elitek-nccn-org"
              />
              <IconCard
                alt="CancerCare"
                cardClass="external-link-card clickable-title"
                cardTitle="CancerCare"
                cardText=""
                isModalLink={true}
                modalLink="https://www.cancercare.org/"
                modalmodalLinkId="cancercare-org"
                isClickableTitle={true}
                modalLinkId="patient-elitek-cancercare-org"
              />
              <IconCard
                alt="FindHelp.org"
                cardClass="external-link-card clickable-title"
                cardTitle="FindHelp.org"
                cardText=""
                isModalLink={true}
                modalLink="https://www.Findhelp.org/"
                modalmodalLinkId="findhelp-org"
                isClickableTitle={true}
                modalLinkId="patient-elitek-findhelp-org"
              />
            </div>
          </div>
          <p className="hang m-b-0">
            *This listing is provided as a resource only and does not constitute an endorsement by Sanofi of any particular organization or its programming. Additional resources on this topic may be available and should be investigated. Sanofi does not review or control the content of non-Sanofi websites.             
          </p>
        </div>
    </>
  );
};

export default ResourceLinks;
