import React from "react"
import { Link } from "gatsby"
import "./internalButton.scss"


const InternalButton = ({url, text, title, className, hasTarget, target, buttonIcon, linkId}) => (	
	<Link id={linkId} to={url} className={"internal-btn " + className} title={title}>
		<span>{text}</span>
		{buttonIcon && <img src={buttonIcon} />}
	</Link>

	// {hasTarget &&
	// 	<Link to={url} className={"internal-btn " + className} title={title} target={target} rel="noreferrer">{text}</Link>
	// }
);

export default InternalButton