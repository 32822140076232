import React from "react"
import "./modal.scss"
import Button from "../button/button"

export default class ModalOverlay extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      modalState: this.props.modalState
    }
  }

  handleClick = () => {
    if (this.state.modalState === "off"){
      this.setState({
        modalState: "on"
      })
    }
    else{
      this.setState({
        modalState: "off"
      })
    }
  }


  componentDidMount() {
    // console.log(this.props.modalState)
  	// modalEventsFunction();
  }

	render(){

		return (
      <div className="modal-wrap">
        <nobr/><div className="modal-cont" id={this.props.trackingID + "-link"} onClick={this.handleClick}>{this.props.children}</div>
  			<div className={this.state.modalState + " modal"} tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header"></div>
                <div className="modal-body">
                  <div className="modal-message">{this.props.message}</div>
                </div>
                <div className="modal-footer">
                  <a className="btn white-light-blue" onClick={this.handleClick}>Close</a>
                  <Button className="light-blue" text="Continue" url={this.props.link} />
                </div>
              </div>
          </div>
        </div>
      </div>
		)
	}

}

ModalOverlay.defaultProps =
  {
    modalState: "off",
    message : "",
    link : ""
  }
